import React, { Fragment } from 'react';

import { Grid, Typography } from '@nestoca/ui';
import { useRecoilValue } from 'recoil';

import { EditableCell } from 'components/editable';
import { ASSET_TYPE, INSTITUTION } from 'constants/appConstants';
import { useI18n } from 'providers/i18n/use-i18n';
import { getApplicant, getApplicantIdSelected } from 'store/applications';
import { AssetFinancial } from 'types/asset';
import { SectionDetailsProps } from 'types/submission-notes';
import { formatMoney } from 'utils';

import { Comments } from './comments';
import { EmptySection } from './empty-section';
import styles from './section-details.module.scss';

export const Assets = ({
    sectionCompleted,
    title,
    submissionNotes,
}: SectionDetailsProps) => {
    const { i18n } = useI18n();

    const { applicationId } = submissionNotes;

    const { assets } = submissionNotes?.customFields || {};

    const applicantId = useRecoilValue(getApplicantIdSelected(applicationId));

    const { allAssets } = useRecoilValue(
        getApplicant({ applicationId, applicantId })
    );

    if (allAssets.length === 0) {
        return <EmptySection applicationId={applicationId} title={title} />;
    }

    return (
        <>
            <Typography>{i18n._('assetsAndDownPayment')}</Typography>
            <Grid className={styles.grid}>
                {allAssets.map((asset: AssetFinancial) => {
                    const {
                        id,
                        amountUsedForDownPayment,
                        institution,
                        value,
                        type,
                    } = asset;

                    return (
                        <Fragment key={id}>
                            <EditableCell
                                name="assetValue"
                                fieldType="readonly"
                                label="value"
                                value={`${formatMoney(value)}`}
                            />
                            <EditableCell
                                name="assetType"
                                fieldType="readonly"
                                label="type"
                                value={ASSET_TYPE(i18n)[type]}
                            />

                            {institution && (
                                <EditableCell
                                    name="assetInstitution"
                                    fieldType="readonly"
                                    label="institution"
                                    value={INSTITUTION(i18n)[institution]}
                                />
                            )}
                            {amountUsedForDownPayment && (
                                <EditableCell
                                    name="usedForDownpayment"
                                    fieldType="readonly"
                                    label="usedForDownpayment"
                                    value={`${formatMoney(
                                        amountUsedForDownPayment
                                    )}`}
                                />
                            )}
                            <Comments
                                isEditing={!sectionCompleted}
                                name={`assets[${id}].comments`}
                                label="description"
                                value={assets[`${id}`]?.comments}
                            />
                        </Fragment>
                    );
                })}
            </Grid>
        </>
    );
};
