import React, { forwardRef } from 'react';

import { Flex } from 'reflexbox/styled-components';
import styled from 'styled-components';
import { variant, border, grid, compose } from 'styled-system';

import type { BoxProps } from 'reflexbox/styled-components';
import type { ResponsiveValue, BorderProps, GridProps } from 'styled-system';
import type { Assign } from 'types/components';

export interface CellOwnProps extends BoxProps, BorderProps, GridProps {
    // As prop is defined into BoxProps
    // as?: React.ElementType;
    variant?: ResponsiveValue<Variant>;
}

export type CellProps = Assign<
    React.ComponentPropsWithRef<'div'>,
    CellOwnProps
>;

export type Variant =
    | 'primary'
    | 'secondary'
    | 'lightGray'
    | 'blue'
    | 'link'
    | 'dark'
    | 'warning'
    | 'discrepancies'
    | 'error';

export const CellStyled = styled(Flex)(
    { flexDirection: 'column', padding: '3px' },
    variant({
        scale: 'cells',
        prop: 'variant',
    }),
    compose(border, grid)
);

export type CellType = BoxProps & CellProps;

export const Cell = forwardRef(
    (props: CellType, ref: React.Ref<HTMLDivElement>) => (
        <CellStyled ref={ref} {...props} />
    )
);
