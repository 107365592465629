import React from 'react';

import { Flex, Grid } from '@nestoca/ui';

import { EditableCell } from 'components/editable';
import { SectionDetailsProps } from 'types/submission-notes';

import { Comments } from './comments';
import styles from './section-details.module.scss';

export const CreditProfile = ({
    sectionCompleted,
    submissionNotes,
}: SectionDetailsProps) => {
    const { highestScore } = submissionNotes?.applicationFields?.credit || {};

    const { comments } = submissionNotes?.customFields?.credit || {};

    return (
        <>
            <Grid className={styles.grid}>
                <EditableCell
                    name="highestScore"
                    fieldType="readonly"
                    label="highestCreditScore"
                    value={highestScore}
                />
            </Grid>
            <Flex className={styles['bottom-container']}>
                <Comments
                    isEditing={!sectionCompleted}
                    name="credit.comments"
                    label="comments"
                    value={comments}
                />
            </Flex>
        </>
    );
};
