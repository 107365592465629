import React, { forwardRef, useMemo, useState } from 'react';

import { Trans } from '@lingui/react';
import css from '@styled-system/css';
import { format, parseISO } from 'date-fns';
import DOMPurify from 'dompurify';
import { MdOutlineContentCopy, MdCheck } from 'react-icons/md';
import useClipboard from 'react-use-clipboard';
import { Box, Flex } from 'reflexbox/styled-components';

import { Cell } from 'components/cell/cell';
import { Popper } from 'components/popper/popper';
import { Text } from 'components/text/text';
import { useI18n } from 'providers/i18n/use-i18n';
import {
    dateNormalizer,
    formatMoney,
    formatPhone,
    isBool,
    maxTwoDecimal,
} from 'utils';
import { mergeRefs } from 'utils/fns';

import type { CellType } from 'components/cell/cell';
import type { SelectOptions } from 'types';

export type CellDataProps = {
    value?: string | number | boolean | string[] | number[] | boolean[];
    fieldType?: string;
    options?: SelectOptions<string | number>;
    children?: React.ReactNode;
    isMulti?: any;
    isClearable?: any;
    required?: boolean;
    toolTipLabel?: string;
    tx?: string;
    withCopy?: boolean;
} & CellType;

const getColor = (variant, isLabel = false) =>
    ['blue', 'dark', 'warning'].includes(variant as string)
        ? 'inherit'
        : isLabel
          ? 'gray-2'
          : variant === 'link'
            ? 'neutral-3'
            : 'portGore';

const fromBooleanValue = (value?: string | boolean) => {
    const hasBoolValue = isBool(value);

    return hasBoolValue ? (
        <Trans id={value === true || value === 'true' ? 'yes' : 'no'} />
    ) : value ? (
        value.toString()
    ) : (
        '-'
    );
};

export const CellData = forwardRef(
    (
        {
            value,
            variant = 'primary',
            fieldType = 'text',
            children,
            options,
            toolTipLabel = 'discrepancyField',
            required,
            tx,
            withCopy = false,
            ...rest
        }: CellDataProps,
        ref: React.Ref<HTMLDivElement>
    ) => {
        const { i18n } = useI18n();
        const [isPopperOpen, setIsPopperOpen] = useState(false);
        const [referenceElement, setReferenceElement] = useState(null);

        const formattedValue = useMemo(() => {
            switch (fieldType) {
                case 'date':
                    const normalizedDate = dateNormalizer(value);

                    return normalizedDate &&
                        // @ts-ignore
                        !isNaN(Date.parse(parseISO(normalizedDate)))
                        ? format(parseISO(`${normalizedDate}`), 'dd/MM/yyyy')
                        : '-';
                case 'yesNo':
                    return value ? (
                        <Trans id={value === 'YES' ? 'yes' : 'no'} />
                    ) : (
                        '-'
                    );
                case 'checkbox':
                case 'boolean':
                    return fromBooleanValue(value as string | boolean);
                case 'number':
                    return value ? maxTwoDecimal(value.toString()) : '-';
                case 'simpleNumber':
                    return value ? value : '-';
                case 'money':
                    return value ? formatMoney(value.toString()) : '-';
                case 'phone':
                    return value ? formatPhone(value.toString()) : '-';
                case 'percentage':
                    return value ? `% ${value}` : '-';
                case 'select':
                case 'readonly':
                    if (
                        Array.isArray(options) &&
                        options.length &&
                        !Array.isArray(value)
                    ) {
                        const option = options.find(
                            (option) => option.value === value
                        );
                        return value && option ? option.label : value || '-';
                    }
                    if (
                        Array.isArray(options) &&
                        options.length &&
                        Array.isArray(value)
                    ) {
                        const selected = options
                            .filter((option) =>
                                (value as any[]).includes(option.value)
                            )
                            .map((option) => option.label);

                        return selected.join(', ');
                    }

                    if (isBool(value)) {
                        return fromBooleanValue(value as any);
                    }

                    return value || '-';
                case 'textarea':
                    return value
                        ? value?.toString().replace(/\n/g, '<br />')
                        : '-';
                default:
                    return value ? value?.toString() : '-';
            }
        }, [value, fieldType, options]);

        const showPopper = variant === 'discrepancies';

        const [isCopied, onCopy] = useClipboard(String(formattedValue), {
            // `isCopied` will go back to `false` after 1000ms.
            successDuration: 1000,
        });

        return (
            <>
                <Cell
                    className="cell"
                    data-testid={`cell-${rest?.id || tx}`}
                    ref={mergeRefs(ref, setReferenceElement)}
                    variant={variant}
                    {...rest}
                    onMouseOver={() => showPopper && setIsPopperOpen(true)}
                    onMouseLeave={() => showPopper && setIsPopperOpen(false)}
                >
                    {tx && (
                        <Box
                            className="cell-label"
                            data-testid={`cell-label-${rest?.id || tx}`}
                            css={css({
                                textOverflow: 'ellipsis',
                                overflow: 'hidden',
                                fontSize: 12,
                                color: getColor(variant, true),
                                fontWeight: '500',
                                marginBottom: '-5px',
                            })}
                        >
                            {i18n._(tx)}

                            {required && (
                                <Text
                                    css={css({
                                        color: 'placeholder',
                                        fontSize: 3,
                                    })}
                                    tx="*"
                                />
                            )}
                        </Box>
                    )}
                    {children}
                    <Flex alignItems="center" style={{ gap: 4 }}>
                        {!children &&
                            formattedValue &&
                            fieldType !== 'textarea' && (
                                <Box
                                    className={`cell-value cell-value-${
                                        rest?.id || tx
                                    }`}
                                    data-testid={`cell-value-${rest?.id || tx}`}
                                    css={css({
                                        textOverflow: 'ellipsis',
                                        overflow: 'hidden',
                                        fontWeight: 700,
                                        fontSize: 14,
                                        color: getColor(variant, false),
                                        textDecoration:
                                            variant === 'link'
                                                ? 'underline'
                                                : 'none',
                                    })}
                                >
                                    <span
                                        data-testid={tx
                                            ?.split(' ')
                                            ?.join('-')
                                            ?.toLowerCase()}
                                    >
                                        {formattedValue}
                                    </span>
                                </Box>
                            )}
                        {!children &&
                            formattedValue &&
                            fieldType === 'textarea' && (
                                <Box
                                    className={`cell-value cell-value-${
                                        rest?.id || tx
                                    }`}
                                    data-testid={`cell-value-${rest?.id || tx}`}
                                    css={css({
                                        textOverflow: 'ellipsis',
                                        overflow: 'hidden',
                                        fontWeight: 600,
                                        fontSize: 14,
                                        color: getColor(variant),
                                    })}
                                    dangerouslySetInnerHTML={{
                                        __html: DOMPurify.sanitize(
                                            String(formattedValue)
                                        ),
                                    }}
                                />
                            )}
                        {withCopy && (
                            <Box flexShrink={0} role="button" onClick={onCopy}>
                                {isCopied ? (
                                    <MdCheck />
                                ) : (
                                    <MdOutlineContentCopy />
                                )}
                            </Box>
                        )}
                    </Flex>
                </Cell>
                {showPopper && (
                    <Popper
                        isOpen={isPopperOpen}
                        referenceElement={referenceElement}
                        placement="auto"
                    >
                        <Text
                            data-testid={`cell-tooltip-${rest?.id || tx}`}
                            tx={toolTipLabel}
                        />
                    </Popper>
                )}
            </>
        );
    }
);
