import React from 'react';

import { Flex } from '@nestoca/ui';
import cn from 'classnames';

import { isTwoColumnsSectionLayout } from 'components/submission-notes/utils';
import { SectionId } from 'types/submission-notes';

import { Header } from './header';
import styles from './section-details.module.scss';

type Props = {
    applicationId: number;
    children: React.ReactNode;
    sectionId: SectionId;
    sectionCompleted: boolean;
    title: string;
    isRelatedDocumentsVisible: boolean;
};

export const SectionDetails = ({
    applicationId,
    children,
    sectionId,
    sectionCompleted,
    title,
    isRelatedDocumentsVisible,
}: Props) => {
    const twoColumnsLayout = isTwoColumnsSectionLayout(
        sectionId,
        isRelatedDocumentsVisible
    );

    return (
        <Flex
            className={cn(styles['section-details'], {
                [styles['section-details--two-columns']]: twoColumnsLayout,
                [styles['section-details--completed']]: sectionCompleted,
            })}
            direction="column"
            gap={5}
            as="li"
        >
            <Header
                applicationId={applicationId}
                sectionId={sectionId}
                title={title}
            />
            <Flex className={styles.content}>{children}</Flex>
        </Flex>
    );
};
