import React from 'react';

import { Trans } from '@lingui/react';
import { Button, Flex, Typography } from '@nestoca/ui';
import { useRouter } from 'next/router';
import { BsInfoCircle } from 'react-icons/bs';

import styles from './section-details.module.scss';

type Props = {
    applicationId: number;
    title: string;
};

export const EmptySection = ({ applicationId, title }: Props) => {
    const { push } = useRouter();

    const handleRouteChange = () => {
        push(`/applications/${applicationId}`);
    };

    return (
        <Flex className={styles.banner} align="center" gap={3}>
            <BsInfoCircle size={20} color="var(--color-brand)" />
            <Typography size={0} height={2}>
                <Trans
                    id="submissionNoteSection.empty"
                    components={{
                        0: (
                            <Button
                                className={styles.link}
                                size="small"
                                variant="link"
                                onClick={handleRouteChange}
                            />
                        ),
                    }}
                    values={{
                        0: title,
                    }}
                />
            </Typography>
        </Flex>
    );
};
