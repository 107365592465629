import React from 'react';

import { i18n, I18n } from '@lingui/core';

import {
    Assets,
    Income,
    AdvisorChecklist,
    ImportantAdditionalDetails,
    CreditProfile,
    HelocDetails,
    OwnedProperties,
    LawyerLegal,
    TransactionDetails,
    SubjectProperty,
    MortgageProtectionInsurance,
    MortgageRequest,
} from 'components/submission-notes/section-details';
import { SECTION, SubmissionNotesMenuSections } from 'types/submission-notes';
import { SectionId } from 'types/submission-notes';
import { SectionDetailsProps } from 'types/submission-notes';
import yup from 'validations/yup-extended';

export const SUBMISSION_NOTES_SECTIONS_MENU: SubmissionNotesMenuSections[] = [
    {
        name: SECTION.ADVISOR_CHECKLIST,
        label: `submissionNotes.sidebar.advisorChecklist`,
    },
    {
        name: SECTION.TRANSACTION_DETAILS,
        label: `submissionNotes.sidebar.transactionDetails`,
    },
    {
        name: SECTION.SUBJECT_PROPERTY,
        label: `submissionNotes.sidebar.subjectProperty`,
    },
    {
        name: SECTION.MORTGAGE_REQUEST,
        label: `submissionNotes.sidebar.mortgageRequest`,
    },

    {
        name: SECTION.CREDIT_PROFILE,
        label: `submissionNotes.sidebar.creditProfile`,
    },
    {
        name: SECTION.MORTGAGE_PROTECTION_INSURANCE,
        label: `mortgageProtectionInsurance`,
    },
    {
        name: SECTION.LAWYER_LEGAL,
        label: `submissionNotes.sidebar.lawyerLegal`,
    },
    {
        name: SECTION.INCOME,
        label: `submissionNotes.sidebar.income`,
    },
    {
        name: SECTION.ASSETS,
        label: `submissionNotes.sidebar.assets`,
    },
    {
        name: SECTION.OWNED_PROPERTIES,
        label: `ownedProperties`,
    },
    {
        name: SECTION.IMPORTANT_ADDITIONAL_DETAILS,
        label: `submissionNotes.sidebar.importantAdditionalDetails`,
    },
    {
        name: SECTION.SUBMIT_SUBMISSION_NOTES,
        label: `submissionNotes.sidebar.submitSubmissionNotes`,
        subtitle: `submissionNotes.submit.subTitle`,
    },
];

export const HELOC_SUBMISSION_NOTES_SECTIONS_MENU: SubmissionNotesMenuSections[] =
    [
        {
            name: SECTION.ADVISOR_CHECKLIST,
            label: `submissionNotes.sidebar.advisorChecklist`,
        },
        {
            name: SECTION.TRANSACTION_DETAILS,
            label: `submissionNotes.sidebar.transactionDetails`,
        },
        {
            name: SECTION.SUBJECT_PROPERTY,
            label: `submissionNotes.sidebar.subjectProperty`,
        },
        {
            name: SECTION.MORTGAGE_REQUEST,
            label: `submissionNotes.sidebar.mortgageRequest`,
        },

        {
            name: SECTION.CREDIT_PROFILE,
            label: `submissionNotes.sidebar.creditProfile`,
        },
        {
            name: SECTION.HELOC_DETAILS,
            label: `submissionNotes.sidebar.helocDetails`,
        },
        {
            name: SECTION.MORTGAGE_PROTECTION_INSURANCE,
            label: `mortgageProtectionInsurance`,
        },
        {
            name: SECTION.LAWYER_LEGAL,
            label: `submissionNotes.sidebar.lawyerLegal`,
        },
        {
            name: SECTION.INCOME,
            label: `submissionNotes.sidebar.income`,
        },
        {
            name: SECTION.ASSETS,
            label: `submissionNotes.sidebar.assets`,
        },
        {
            name: SECTION.OWNED_PROPERTIES,
            label: `ownedProperties`,
        },
        {
            name: SECTION.IMPORTANT_ADDITIONAL_DETAILS,
            label: `submissionNotes.sidebar.importantAdditionalDetails`,
        },
        {
            name: SECTION.SUBMIT_SUBMISSION_NOTES,
            label: `submissionNotes.sidebar.submitSubmissionNotes`,
            subtitle: `submissionNotes.submit.subTitle`,
        },
    ];

export const STANDALONE_HELOC_SUBMISSION_NOTES_SECTIONS_MENU: SubmissionNotesMenuSections[] =
    [
        {
            name: SECTION.ADVISOR_CHECKLIST,
            label: `submissionNotes.sidebar.advisorChecklist`,
        },
        {
            name: SECTION.TRANSACTION_DETAILS,
            label: `submissionNotes.sidebar.transactionDetails`,
        },
        {
            name: SECTION.SUBJECT_PROPERTY,
            label: `submissionNotes.sidebar.subjectProperty`,
        },

        {
            name: SECTION.CREDIT_PROFILE,
            label: `submissionNotes.sidebar.creditProfile`,
        },
        {
            name: SECTION.HELOC_DETAILS,
            label: `submissionNotes.sidebar.helocDetails`,
        },
        {
            name: SECTION.LAWYER_LEGAL,
            label: `submissionNotes.sidebar.lawyerLegal`,
        },
        {
            name: SECTION.INCOME,
            label: `submissionNotes.sidebar.income`,
        },
        {
            name: SECTION.ASSETS,
            label: `submissionNotes.sidebar.assets`,
        },
        {
            name: SECTION.OWNED_PROPERTIES,
            label: `ownedProperties`,
        },
        {
            name: SECTION.IMPORTANT_ADDITIONAL_DETAILS,
            label: `submissionNotes.sidebar.importantAdditionalDetails`,
        },
        {
            name: SECTION.SUBMIT_SUBMISSION_NOTES,
            label: `submissionNotes.sidebar.submitSubmissionNotes`,
            subtitle: `submissionNotes.submit.subTitle`,
        },
    ];

export const PRE_APP_SUBMISSION_NOTES_SECTIONS_MENU: SubmissionNotesMenuSections[] =
    SUBMISSION_NOTES_SECTIONS_MENU.filter(
        (section) =>
            section.name !== SECTION.CREDIT_PROFILE &&
            section.name !== SECTION.LAWYER_LEGAL &&
            section.name !== SECTION.ADVISOR_CHECKLIST
    );

export const READVANCE_SUBMISSION_NOTES_SECTIONS_MENU = [
    {
        name: SECTION.TRANSACTION_DETAILS,
        label: `submissionNotes.sidebar.transactionDetails`,
    },
    {
        name: SECTION.SUBJECT_PROPERTY,
        label: `submissionNotes.sidebar.subjectProperty`,
    },
    {
        name: SECTION.MORTGAGE_REQUEST,
        label: `submissionNotes.sidebar.mortgageRequest`,
    },
    {
        name: SECTION.CREDIT_PROFILE,
        label: `submissionNotes.sidebar.creditProfile`,
    },
    {
        name: SECTION.MORTGAGE_PROTECTION_INSURANCE,
        label: `mortgageProtectionInsurance`,
    },
    {
        name: SECTION.INCOME,
        label: `submissionNotes.sidebar.income`,
    },
    {
        name: SECTION.ASSETS,
        label: `submissionNotes.sidebar.assets`,
    },
    {
        name: SECTION.OWNED_PROPERTIES,
        label: `ownedProperties`,
    },
    {
        name: SECTION.IMPORTANT_ADDITIONAL_DETAILS,
        label: `submissionNotes.sidebar.importantAdditionalDetails`,
    },
    {
        name: SECTION.SUBMIT_SUBMISSION_NOTES,
        label: `submissionNotes.sidebar.submitSubmissionNotes`,
        subtitle: `submissionNotes.submit.subTitle`,
    },
];

export const PRESENTED_TO_CLIENT_OPTIONS = (i18n: I18n) => [
    {
        value: 'NO',
        label: i18n._(`mortgageProtectionInsurance.PresentedToClient.No`),
    },
    {
        value: 'YES_AND_INTERESTED',
        label: i18n._(
            `mortgageProtectionInsurance.PresentedToClient.YesAndInterested`
        ),
    },
    {
        value: 'YES_AND_NOT_INTERESTED',
        label: i18n._(
            `mortgageProtectionInsurance.PresentedToClient.YesAndNotInterested`
        ),
    },
];

export const SECTIONS: {
    id: SectionId;
    title: string;
    component: (props: SectionDetailsProps) => React.ReactElement;
}[] = [
    {
        id: SECTION.ADVISOR_CHECKLIST,
        title: i18n._('submissionNote.advisorChecklist'),
        component: AdvisorChecklist,
    },
    {
        id: SECTION.TRANSACTION_DETAILS,
        title: i18n._('submissionNote.transactionDetails'),
        component: TransactionDetails,
    },
    {
        id: SECTION.SUBJECT_PROPERTY,
        title: i18n._('submissionNote.subjectProperty'),
        component: SubjectProperty,
    },
    {
        id: SECTION.MORTGAGE_REQUEST,
        title: i18n._('submissionNote.mortgageRequest'),
        component: MortgageRequest,
    },
    {
        id: SECTION.CREDIT_PROFILE,
        title: i18n._('submissionNote.creditProfile'),
        component: CreditProfile,
    },
    {
        id: SECTION.HELOC_DETAILS,
        title: i18n._('submissionNote.helocDetails'),
        component: HelocDetails,
    },
    {
        id: SECTION.MORTGAGE_PROTECTION_INSURANCE,
        title: i18n._('submissionNote.mortgageProtectionInsurance'),
        component: MortgageProtectionInsurance,
    },
    {
        id: SECTION.LAWYER_LEGAL,
        title: i18n._('submissionNote.lawyerLegal'),
        component: LawyerLegal,
    },
    {
        id: SECTION.INCOME,
        title: i18n._('submissionNote.income'),
        component: Income,
    },
    {
        id: SECTION.ASSETS,
        title: i18n._('submissionNote.assets'),
        component: Assets,
    },
    {
        id: SECTION.OWNED_PROPERTIES,
        title: i18n._('submissionNote.ownedProperties'),
        component: OwnedProperties,
    },
    {
        id: SECTION.IMPORTANT_ADDITIONAL_DETAILS,
        title: i18n._('submissionNote.importantAdditionalDetails'),
        component: ImportantAdditionalDetails,
    },
];

export const REMOVED_SECTIONS_ID: {
    [key: string]: SectionId[];
} = {
    PRE_APPROVAL: [
        SECTION.ADVISOR_CHECKLIST,
        SECTION.CREDIT_PROFILE,
        SECTION.LAWYER_LEGAL,
        SECTION.HELOC_DETAILS,
    ],
    READVANCE: [
        SECTION.ADVISOR_CHECKLIST,
        SECTION.LAWYER_LEGAL,
        SECTION.HELOC_DETAILS,
    ],
    STANDALONE_HELOC: [],
    HELOC: [SECTION.MORTGAGE_REQUEST, SECTION.MORTGAGE_PROTECTION_INSURANCE],
    OTHER: [SECTION.HELOC_DETAILS],
};

export const SUBMIT_SUBMISSION_NOTES_MISSING_FIELDS = {
    [SECTION.TRANSACTION_DETAILS]: yup.object().shape({
        maritalDetails: yup.string().required(),
        appraisalAdditionalComments: yup.string().required(),
    }),
    [SECTION.CREDIT_PROFILE]: yup.object().shape({
        creditComments: yup.string().required(),
    }),
    [SECTION.MORTGAGE_PROTECTION_INSURANCE]: yup.object().shape({
        presentedToClient: yup.string().when(['$isStandAloneHeloc'], {
            is: (isStandAloneHeloc) => !isStandAloneHeloc,
            then: yup.string().required(),
        }),
        explanationIfNo: yup.string().when('presentedToClient', {
            is: 'NO',
            then: yup.string().required(),
        }),
    }),
    [SECTION.LAWYER_LEGAL]: yup.object().shape({
        notary: yup.string().required(),
        discussedLegalFees: yup.boolean().required().oneOf([true]),
    }),
    [SECTION.IMPORTANT_ADDITIONAL_DETAILS]: yup.object().shape({
        importantDetails: yup.string().required(),
    }),
    [SECTION.HELOC_DETAILS]: yup.object().shape({
        helocAdditionalComments: yup.string().when(['$isHelocAmountPresent'], {
            is: (isHelocAmountPresent: boolean) => isHelocAmountPresent,
            then: yup.string().required(),
        }),
    }),
};

export const SUBMISSION_NOTES_IMPORTANT_FIELDS = {
    ...SUBMIT_SUBMISSION_NOTES_MISSING_FIELDS,
    [SECTION.ADVISOR_CHECKLIST]: yup.object().shape({
        advisorCheckOne: yup.boolean().required().oneOf([true]),
        advisorCheckTwo: yup.boolean().required().oneOf([true]),
        advisorCheckThree: yup.boolean().required().oneOf([true]),
        advisorCheckFour: yup.boolean().required().oneOf([true]),
        advisorCheckFive: yup.boolean().required().oneOf([true]),
    }),
};
