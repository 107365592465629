import React from 'react';

import { Flex, Typography } from '@nestoca/ui';
import cn from 'classnames';
import { BsChatSquareText } from 'react-icons/bs';

import { EditableCell, EditableCellProps } from 'components/editable';
import { useI18n } from 'providers/i18n/use-i18n';

import styles from './section-details.module.scss';

type Props = {
    error?: boolean;
} & Pick<
    EditableCellProps,
    'isEditing' | 'label' | 'name' | 'required' | 'value'
>;

export const Comments = ({ error, ...rest }: Props) => {
    const { i18n } = useI18n();

    const { isEditing, label, name, required, value } = rest;

    return (
        <Flex
            className={cn(styles.comments, {
                [styles['comments--error']]: error,
                [styles['comments--required']]: required,
            })}
            gap={3}
        >
            <BsChatSquareText size={20} color="var(--color-brand)" />
            <Flex
                className={styles['right-container']}
                direction="column"
                grow={1}
            >
                <Typography className={styles.label} size={0} weight={7}>
                    {i18n._(label)}
                </Typography>
                <EditableCell
                    isEditing={isEditing}
                    name={name}
                    fieldType="textarea"
                    label={label}
                    value={value}
                />
            </Flex>
        </Flex>
    );
};
