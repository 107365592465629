import React from 'react';

import { Grid } from '@nestoca/ui';

import { EditableCell } from 'components/editable';

import styles from './section-details.module.scss';

export const AdvisorChecklist = () => {
    return (
        <Grid className={styles.grid}>
            <EditableCell
                isEditing
                name="advisorCheckOne"
                fieldType="checkbox"
                label="advisorChecklist.checkOne"
                squareCheckbox
            />
            <EditableCell
                isEditing
                name="advisorCheckTwo"
                fieldType="checkbox"
                label="advisorChecklist.checkTwo"
                squareCheckbox
            />
            <EditableCell
                isEditing
                name="advisorCheckThree"
                fieldType="checkbox"
                label="advisorChecklist.checkThree"
                squareCheckbox
            />
            <EditableCell
                isEditing
                name="advisorCheckFour"
                fieldType="checkbox"
                label="advisorChecklist.checkFour"
                squareCheckbox
            />
            <EditableCell
                isEditing
                name="advisorCheckFive"
                fieldType="checkbox"
                label="advisorChecklist.checkFive"
                squareCheckbox
            />
        </Grid>
    );
};
