import React from 'react';

import { Flex } from '@nestoca/ui';

import { SectionDetailsProps } from 'types/submission-notes';

import { Comments } from './comments';
import styles from './section-details.module.scss';

export const ImportantAdditionalDetails = ({
    sectionCompleted,
    submissionNotes,
}: SectionDetailsProps) => {
    const { importantDetails } = submissionNotes?.customFields || {};

    return (
        <Flex className={styles['bottom-container']}>
            <Comments
                isEditing={!sectionCompleted}
                name="importantDetails"
                label="importantAdditionalDetails"
                value={importantDetails}
            />
        </Flex>
    );
};
