import React from 'react';

import { Trans } from '@lingui/react';
import { useFormContext } from 'react-hook-form';

import { Button } from 'components/button-v2';
import { Grid } from 'components/grid/grid';
import { Spinner } from 'components/spinner';

type EditableToolBarProps = {
    onCancel: () => void;
    onDestroy?: () => void;
};

export const EditableToolBar = ({
    onCancel,
    onDestroy,
}: EditableToolBarProps) => {
    const {
        formState: { isSubmitting },
    } = useFormContext();

    return (
        <Grid
            className="editable-toolbar"
            css={{
                position: 'absolute',
                top: -40,
                right: 0,
                zIndex: 10,
                gridColumnGap: 8,
                gridAutoRows: 'auto',
                gridTemplateColumns: 'repeat(auto-fill, 1fr)',
                gridAutoFlow: 'column',
            }}
        >
            {onDestroy && (
                <Button
                    data-testid="delete-button"
                    variant="ghost"
                    onClick={onDestroy}
                >
                    <Trans id="delete" />
                </Button>
            )}
            <Button
                data-testid="cancel-button"
                variant="ghost"
                onClick={onCancel}
            >
                <Trans id="cancel" />
            </Button>
            <Button
                data-testid="save-button"
                name="save"
                variant="ghost"
                type="submit"
                iconRight={isSubmitting ? <Spinner size={16} /> : null}
                data-issubmitting={isSubmitting}
            >
                <Trans id="save" />
            </Button>
        </Grid>
    );
};
