import React, { forwardRef } from 'react';

import { Box } from 'reflexbox/styled-components';
import styled from 'styled-components';
import { grid } from 'styled-system';

import type { CssFunctionReturnType, CSSObject } from '@styled-system/css';
import type { BoxProps } from 'reflexbox/styled-components';
import type { GridProps as StyledGridProps } from 'styled-system';
import type { Assign } from 'types/components';

interface GridOwnProps extends BoxProps, StyledGridProps {
    css?: CssFunctionReturnType | CSSObject;
    onSubmit?: any;
}

export type GridProps = Assign<
    Omit<React.ComponentPropsWithRef<'div'>, 'onSubmit'>,
    GridOwnProps
>;

export const GridStyled = styled(Box)({ display: 'grid' }, grid);

export const Grid = forwardRef(
    (props: GridProps, ref: React.Ref<HTMLDivElement>) => (
        <GridStyled ref={ref} {...props} />
    )
);
