import { useClient } from 'providers/auth0';

export const useIsTenant = () => {
    const { client } = useClient();

    // there is an exception here for the role of the partner (Realtor, Financial Advisor...)
    // the `client` will be undefined because the partner is not on behalf a client.
    const slug = client?.tenant?.slug || 'nesto';

    const isIGTenant = slug === 'ig' || slug === 'ig-dev' || slug === 'ig-sla';
    const isNestoTenant = slug === 'nesto';

    return { isIGTenant, isNestoTenant };
};
