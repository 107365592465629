import React from 'react';

import { Box, Flex, Grid } from '@nestoca/ui';
import { useFormContext } from 'react-hook-form';

import { EditableCell } from 'components/editable';
import { Tooltip } from 'components/tooltip';
import { useI18n } from 'providers/i18n/use-i18n';
import { SectionDetailsProps } from 'types/submission-notes';

import { Comments } from './comments';
import styles from './section-details.module.scss';

export const LawyerLegal = ({
    sectionCompleted,
    submissionNotes,
}: SectionDetailsProps) => {
    const { i18n } = useI18n();

    const { discussedLegalFees, notary } =
        submissionNotes?.customFields?.lawyer || {};

    const { formState, getFieldState } = useFormContext();

    const { error: lawyerNotaryError } = getFieldState(
        'lawyer.notary',
        formState
    );

    return (
        <>
            <Grid className={styles.grid}>
                <Tooltip
                    content={i18n._('discussedLegalFeesPopper')}
                    offset={[0, 12]}
                >
                    <Box>
                        <EditableCell
                            required
                            isEditing={!sectionCompleted}
                            name="lawyer.discussedLegalFees"
                            fieldType="boolean"
                            label="discussedFeesWithClient"
                            value={discussedLegalFees}
                        />
                    </Box>
                </Tooltip>
            </Grid>
            <Flex className={styles['bottom-container']}>
                <Comments
                    required
                    isEditing={!sectionCompleted}
                    name="lawyer.notary"
                    label="lawyerNotary"
                    value={notary}
                    error={lawyerNotaryError !== undefined}
                />
            </Flex>
        </>
    );
};
