import React from 'react';

import { Flex, Grid } from '@nestoca/ui';

import { EditableCell } from 'components/editable';
import { MARITAL_STATUS_OPTIONS } from 'constants/appConstants';
import { useI18n } from 'providers/i18n/use-i18n';
import { SectionDetailsProps } from 'types/submission-notes';
import { formatMoney, formatPercentage } from 'utils';

import { Comments } from './comments';
import styles from './section-details.module.scss';

export const TransactionDetails = ({
    sectionCompleted,
    submissionNotes,
}: SectionDetailsProps) => {
    const { i18n } = useI18n();

    const { appraisalAdditionalComments, maritalDetails } =
        submissionNotes?.customFields?.transaction || {};

    const {
        mortgageAmount,
        mortgageClosingDate,
        helocClosingDate,
        ltv,
        gds,
        tds,
        maritalStatus,
        financialWaiverDate,
        preApproval,
    } = submissionNotes?.applicationFields?.transaction || {};

    const { type } = submissionNotes?.applicationFields?.mortgage || {};

    return (
        <>
            <Grid className={styles.grid}>
                <EditableCell
                    name="type"
                    fieldType="readonly"
                    label="type"
                    value={type}
                />
                <EditableCell
                    name="totalMortgageAmount"
                    fieldType="readonly"
                    label="totalMortgageAmount"
                    value={formatMoney(mortgageAmount)}
                />
                <EditableCell
                    name="ltv"
                    fieldType="readonly"
                    label="ltv"
                    value={formatPercentage(ltv)}
                />
                <EditableCell
                    name="gds"
                    fieldType="readonly"
                    label="gdsLabel"
                    value={formatPercentage(gds)}
                />
                <EditableCell
                    name="tds"
                    fieldType="readonly"
                    label="tdsLabel"
                    value={formatPercentage(tds)}
                />
                {mortgageClosingDate && (
                    <EditableCell
                        name="mortgageClosingDate"
                        fieldType="readonly"
                        label="mortgageClosingDate"
                        value={mortgageClosingDate}
                    />
                )}
                {helocClosingDate && (
                    <EditableCell
                        name="helocClosingDate"
                        fieldType="readonly"
                        label="helocClosingDate"
                        value={helocClosingDate}
                    />
                )}
                <EditableCell
                    name="maritalStatus"
                    fieldType="readonly"
                    label="Marital Status"
                    options={MARITAL_STATUS_OPTIONS(i18n)}
                    value={maritalStatus}
                />
                <EditableCell
                    name="COFDate"
                    fieldType="readonly"
                    label="COFDate"
                    value={financialWaiverDate}
                />
                {preApproval?.goLiveDate && (
                    <>
                        <EditableCell
                            name="goLiveDate"
                            fieldType="readonly"
                            label="goLiveDate"
                            value={preApproval?.goLiveDate}
                        />
                        <EditableCell
                            name="preApprovalDate"
                            fieldType="readonly"
                            label="preApprovalDate"
                            value={preApproval?.preApprovedDate?.split('T')[0]}
                        />
                        <EditableCell
                            name="type"
                            fieldType="readonly"
                            label="preApprovalType"
                            value={preApproval?.type}
                        />
                    </>
                )}
            </Grid>
            <Flex className={styles['bottom-container']}>
                <Comments
                    isEditing={!sectionCompleted}
                    name="transaction.maritalDetails"
                    label="maritalDetails"
                    value={maritalDetails}
                />
                <Comments
                    isEditing={!sectionCompleted}
                    name="transaction.appraisalAdditionalComments"
                    label="appraisalAdditionalComments"
                    value={appraisalAdditionalComments}
                />
            </Flex>
        </>
    );
};
