import React, { forwardRef, useImperativeHandle } from 'react';

import { useFlags } from 'launchdarkly-react-client-sdk';
import {
    FormProvider,
    SubmitErrorHandler,
    useForm,
    useFormState,
} from 'react-hook-form';
import { Box } from 'reflexbox/styled-components';

import { Grid } from 'components/grid/grid';
import { Spinner } from 'components/spinner';
import { useToasts } from 'components/toast';
import { useI18n } from 'providers/i18n/use-i18n';
import { useEditingRights } from 'store/rights';
import { ValidationError, isEmpty } from 'utils';
import { EditableKey, useEditingContext } from 'utils/use-editing-context';

import { EditableToolBar } from './editable-toolbar';

import type { GridProps } from 'components/grid/grid';

type EditableEditProps = {
    // children,
    editableKey?: EditableKey;
    id?: string;
    onSubmit: (
        data: any,
        e?: Event
    ) => void | Promise<void> /* SubmitHandler<TSubmitFieldValues> */;
    onInvalid?: SubmitErrorHandler<any>;
    onDestroy?: () => void;
    resolver?: any;
    defaultValues?: any;
    normalizeData?: (values: any) => any;
    hideToolbar?: boolean;
    context?: Record<string, unknown>;
};

type EditableGridProps = GridProps & EditableEditProps;

export const EditableGrid = forwardRef(
    (
        {
            editableKey,
            children,
            id,
            onSubmit,
            onDestroy,
            onInvalid,
            resolver,
            defaultValues,
            normalizeData,
            ...rest
        }: EditableGridProps,
        ref: React.Ref<HTMLDivElement>
    ) => {
        const { applicationReview: showApplicationReview } = useFlags();

        const hasEditingRights = useEditingRights();

        const { editingKey, setEditingKey } = useEditingContext();
        const isEditing = editableKey === editingKey;

        const handleDoubleClick = () => {
            if (showApplicationReview && editableKey && hasEditingRights) {
                setEditingKey(editableKey);
            }
        };

        if (isEditing) {
            return (
                <EditGrid
                    id={id}
                    onSubmit={onSubmit}
                    onDestroy={onDestroy}
                    onInvalid={onInvalid}
                    resolver={resolver}
                    defaultValues={defaultValues}
                    normalizeData={normalizeData}
                    onDoubleClick={handleDoubleClick}
                    css={{ position: 'relative' }}
                    {...rest}
                >
                    {children}
                </EditGrid>
            );
        }

        return (
            <Grid
                ref={ref}
                onDoubleClick={handleDoubleClick}
                css={{ position: 'relative' }}
                {...rest}
            >
                {children}
            </Grid>
        );
    }
);

const ErrorsConsoleLog = () => {
    const { errors } = useFormState();

    if (!isEmpty(errors)) {
        // eslint-disable-next-line no-console
        console.log(
            '%c form errors ',
            'background: #AF2F0D; color: #FFF',
            errors
        );
    }
    return null;
};
const SpinnerForm = () => {
    const { isSubmitting } = useFormState();

    return (
        isSubmitting && (
            <div
                css={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'white',
                    opacity: 0.8,
                }}
            >
                <Spinner
                    css={{
                        position: 'absolute',
                        left: '50%',
                        top: '50%',
                    }}
                />
            </div>
        )
    );
};

export const EditGrid = forwardRef(
    (
        {
            children,
            gridArea,
            id,
            onSubmit,
            onInvalid,
            resolver,
            defaultValues,
            normalizeData,
            hideToolbar = false,
            onDestroy,
            context,
            ...rest
        }: Omit<EditableEditProps, 'editableKey'> & GridProps,
        ref: any
    ) => {
        const { i18n } = useI18n();
        const { addToast } = useToasts();
        const { clearEditingKey } = useEditingContext();
        const methods = useForm({
            resolver,
            defaultValues,
            context,
        });

        useImperativeHandle(ref, () => ({ ...methods }), [methods]);

        const handleSubmit = async (values) => {
            try {
                const normalizedData = normalizeData
                    ? normalizeData({
                          ...defaultValues,
                          ...values,
                      })
                    : {
                          ...defaultValues,
                          ...values,
                      };

                await onSubmit(values);

                // Reset RHF for next mount of editable fields
                methods.reset(normalizedData);
            } catch (error) {
                const isValidationError = error instanceof ValidationError;
                if (!isValidationError) {
                    // eslint-disable-next-line no-console
                    console.warn(
                        'Failed to submit form, error should be handle in the submit function',
                        error
                    );
                    // If not handle into the component we can show a generic toast message
                    addToast(`Error: ${i18n._({ id: 'failedToSave' })}`, {
                        appearance: 'error',
                    });
                }
            }
        };

        return (
            <FormProvider {...methods}>
                <Box
                    id={id}
                    as="form"
                    onSubmit={methods.handleSubmit(handleSubmit, onInvalid)}
                    // TODO : Why gridArea is not valid here
                    // @ts-ignore
                    css={{ gridArea, position: 'relative' }}
                >
                    <Grid css={{ position: 'relative' }} {...rest}>
                        {!hideToolbar && (
                            <EditableToolBar
                                onCancel={clearEditingKey}
                                onDestroy={onDestroy}
                            />
                        )}
                        {children}
                    </Grid>
                    {window?.config?.env === 'dev' && <ErrorsConsoleLog />}
                    <SpinnerForm />
                </Box>
            </FormProvider>
        );
    }
);
