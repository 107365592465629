import { atom, atomFamily, selectorFamily, useSetRecoilState } from 'recoil';

import {
    PRE_APP_SUBMISSION_NOTES_SECTIONS_MENU,
    SUBMISSION_NOTES_SECTIONS_MENU,
    READVANCE_SUBMISSION_NOTES_SECTIONS_MENU,
} from 'constants/submission-notes';
import { client as apiClient } from 'libs/api';
import { ApplicationType } from 'types/application';
import {
    SubmissionNoteType,
    ValidateSubmissionNoteDocumentType,
} from 'types/submission-notes';

export const submissionNotesState = atomFamily({
    key: 'SubmissionNotesState',
    default: (type: ApplicationType) => {
        const isPreApproval = type === 'PRE_APPROVAL';
        const isReadvance = type === 'READVANCE';
        return isPreApproval
            ? PRE_APP_SUBMISSION_NOTES_SECTIONS_MENU[0]
            : isReadvance
            ? READVANCE_SUBMISSION_NOTES_SECTIONS_MENU[0]
            : SUBMISSION_NOTES_SECTIONS_MENU[0];
    },
});

export const getSubmissionNotes = selectorFamily({
    key: 'GetSubmissionNotes',
    get:
        ({ applicationId }: { applicationId: number }) =>
        async () => {
            try {
                const { data } = await apiClient.getSubmissionNotes(
                    applicationId
                );
                return data;
            } catch (error) {
                console.error('getSubmissionNotes error', error);
                return [];
            }
        },
});

export const getSubmissionNote = selectorFamily({
    key: 'GetSubmissionNote',
    get:
        ({
            applicationId,
            submissionNoteId,
        }: {
            applicationId: number;
            submissionNoteId: number;
        }) =>
        async () => {
            try {
                const { data } = await apiClient.getSubmissionNote(
                    applicationId,
                    submissionNoteId
                );

                return data as SubmissionNoteType;
            } catch (error) {
                console.error('getSubmissionNote error', error);
                return [];
            }
        },
});
// picks the first submission note from the list
export const getCurrentSubmissionNote = selectorFamily({
    key: 'GetCurrentSubmissionNote',
    get:
        ({ applicationId }: { applicationId: number }) =>
        async ({ get }) => {
            const submissionNotesList = get(
                getSubmissionNotesState({ applicationId })
            );

            const currentSubmissionNote = submissionNotesList[0];
            return currentSubmissionNote as SubmissionNoteType;
        },
});

export const getSubmissionNoteFromState = selectorFamily({
    key: 'getSubmissionNoteFromState',
    get:
        ({
            applicationId,
            submissionNoteId,
        }: {
            applicationId: number;
            submissionNoteId: number;
        }) =>
        async ({ get }) => {
            const submissionNotesList = get(
                getSubmissionNotesState({ applicationId })
            );

            const submissionNote = submissionNotesList.find(
                (submissionNote) => submissionNote.id === submissionNoteId
            );

            return submissionNote as SubmissionNoteType;
        },
});

export const useRefreshSubmissionNotes = (applicationId) => {
    const setSubmissionNotes = useSetRecoilState(
        getSubmissionNotesState({ applicationId })
    );

    return async () => {
        const { data } = await apiClient.getSubmissionNotes(applicationId);
        setSubmissionNotes(data);
        return data;
    };
};

export const getSubmissionNotesState = atomFamily({
    key: 'getSubmissionNotesState',
    default: getSubmissionNotes,
});

export const getHasSubmissionNoteSubmitted = selectorFamily({
    key: 'getHasSubmissionNoteSubmitted',
    get:
        (applicationId: number) =>
        ({ get }) => {
            const submissionNotes: SubmissionNoteType[] = get(
                getSubmissionNotesState({ applicationId })
            );

            return submissionNotes.some(({ state }) => state === 'SUBMITTED');
        },
});

export const getDocumentValidation = selectorFamily({
    key: 'GetDocumentValidation',
    get:
        ({ applicationId }: { applicationId: number }) =>
        async () => {
            try {
                const { data } = await apiClient.validateDocuments(
                    applicationId
                );
                return data;
            } catch (error) {
                console.error('getDocumentValidation error', error);
                return {} as ValidateSubmissionNoteDocumentType;
            }
        },
});

export const useRefreshDocumentValidation = (applicationId) => {
    const setDocumentValidation = useSetRecoilState(
        getDocumentValidationState({ applicationId })
    );

    return async () => {
        const { data } = await apiClient.validateDocuments(applicationId);
        setDocumentValidation(data);
        return data;
    };
};

export const getDocumentValidationState = atomFamily({
    key: 'getDocumentValidationState',
    default: getDocumentValidation,
});

export const getCompletedSectionsId = atom<{ [key: number]: string[] }>({
    key: 'getCompletedSectionsId',
    default: {},
});

export const getCompletedSectionsIdByApplicant = selectorFamily({
    key: 'getCompletedSectionsIdByApplicant',
    get:
        (applicantId: number) =>
        ({ get }) =>
            get(getCompletedSectionsId)[applicantId] || [],
});

export const getNbOfCompletedSections = selectorFamily({
    key: 'getNbOfCompletedSections',
    get:
        (applicantId: number) =>
        ({ get }) =>
            get(getCompletedSectionsIdByApplicant(applicantId)).length,
});

export const getIsSectionCompleted = selectorFamily({
    key: 'getIsSectionCompleted',
    get:
        ({
            applicantId,
            sectionId,
        }: {
            applicantId: number;
            sectionId: string;
        }) =>
        ({ get }) =>
            get(getCompletedSectionsIdByApplicant(applicantId)).includes(
                sectionId
            ),
});
