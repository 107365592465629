import { useRecoilValue } from 'recoil';

import { ACTIVE_APPLICATION_PROBLEM_STATES } from 'constants/problem';
import applicationStore from 'store/applications';

export const usePartialValidation = () => {
    const application = useRecoilValue(applicationStore.selectedApplication);

    if (!application) {
        return false;
    }

    return ACTIVE_APPLICATION_PROBLEM_STATES.includes(
        application?.applicationState
    );
};
