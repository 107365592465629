import React, { forwardRef } from 'react';

import { Box } from 'reflexbox/styled-components';
import { keyframes } from 'styled-components';

const spin = keyframes({
    from: {
        transform: 'rotate(0deg)',
    },
    to: {
        transform: 'rotate(360deg)',
    },
});

type Props = {
    size?: number;
    strokeWidth?: number;
    title?: string;
    duration?: number;
} & Omit<
    React.ComponentPropsWithRef<'svg'>,
    'opacity' | 'color' | 'css' | 'sx'
>;

export const Spinner = forwardRef(
    (
        {
            size = 48,
            strokeWidth = 4,
            title = 'Loading...',
            duration = 500,
            ...rest
        }: Props,
        ref
    ) => {
        const r = 16 - strokeWidth;
        const C = 2 * r * Math.PI;
        const offset = C - (1 / 4) * C;

        return (
            <Box
                // eslint-disable-next-line
                // @ts-ignore
                ref={ref}
                as="svg"
                viewBox="0 0 32 32"
                // eslint-disable-next-line
                // @ts-ignore
                width={`${size}px`}
                // eslint-disable-next-line
                // @ts-ignore
                height={`${size}px`}
                strokeWidth={strokeWidth}
                // eslint-disable-next-line
                // @ts-ignore
                fill="none"
                stroke="currentcolor"
                role="img"
                {...rest}
                css={`
                    overflow: visible;
                `}
            >
                <title>{title}</title>
                <circle cx={16} cy={16} r={r} opacity={1 / 8} />
                <Box
                    as="circle"
                    // eslint-disable-next-line
                    // @ts-ignore
                    cx={16}
                    cy={16}
                    r={r}
                    strokeDasharray={C}
                    strokeDashoffset={offset}
                    css={`
                        transform-origin: 50% 50%;
                        animation-name: ${spin};
                        animation-timing-function: linear;
                        animation-duration: ${duration}ms;
                        animation-iteration-count: infinite;
                    `}
                />
            </Box>
        );
    }
);
