import React from 'react';

import { Flex, Heading } from '@nestoca/ui';
import { cloneDeep } from 'lodash';
import { useRecoilState, useRecoilValue } from 'recoil';

import { CheckboxComponent, Label } from 'components/forms';
import { getApplicantIdSelected } from 'store/applications';
import {
    getCompletedSectionsId,
    getIsSectionCompleted,
} from 'store/submission-notes';

import styles from './section-details.module.scss';

type Props = {
    applicationId: number;
    sectionId: string;
    title: string;
};

export const Header = ({ applicationId, sectionId, title }: Props) => {
    const [completedSectionsId, setCompletedSectionsId] = useRecoilState(
        getCompletedSectionsId
    );

    const applicantId = useRecoilValue(getApplicantIdSelected(applicationId));

    const sectionCompleted = useRecoilValue(
        getIsSectionCompleted({ applicantId, sectionId })
    );

    const onCheckboxChange = () => {
        const completedSectionsIdCopy = cloneDeep(completedSectionsId);

        const completedSectionsIdByApplicant =
            completedSectionsIdCopy[applicantId] || [];

        const sectionIdIndex = completedSectionsIdByApplicant.findIndex(
            (id) => id === sectionId
        );

        if (sectionIdIndex !== -1) {
            completedSectionsIdByApplicant.splice(sectionIdIndex, 1);
        } else {
            completedSectionsIdByApplicant.push(sectionId);
        }

        completedSectionsIdCopy[applicantId] = completedSectionsIdByApplicant;

        setCompletedSectionsId(completedSectionsIdCopy);
    };

    return (
        <Flex
            className={styles.header}
            align="center"
            justify="between"
            as="header"
        >
            <Label
                className={styles['header__left-container']}
                htmlFor={`checkbox-section-${sectionId}`}
            >
                <CheckboxComponent
                    id={`checkbox-section-${sectionId}`}
                    checked={sectionCompleted}
                    onChange={onCheckboxChange}
                    squareCheckbox
                />
                <Heading size={6} weight={7}>
                    {title}
                </Heading>
            </Label>
            {/* Actions (bookmark, add note, ...) */}
        </Flex>
    );
};
