import React from 'react';

import { Flex, Grid } from '@nestoca/ui';
import { useFormContext } from 'react-hook-form';

import { EditableCell } from 'components/editable';
import { PRESENTED_TO_CLIENT_OPTIONS } from 'constants/submission-notes';
import { useI18n } from 'providers/i18n/use-i18n';
import { SectionDetailsProps } from 'types/submission-notes';

import { Comments } from './comments';
import styles from './section-details.module.scss';

export const MortgageProtectionInsurance = ({
    sectionCompleted,
    submissionNotes,
}: SectionDetailsProps) => {
    const { i18n } = useI18n();

    const { presentedToClient, explanationIfNo } =
        submissionNotes?.customFields?.mortgageProtectionInsurance || {};

    const { watch, formState, getFieldState } = useFormContext();

    const { error: explanationIfNoError } = getFieldState(
        'mortgageProtectionInsurance.explanationIfNo',
        formState
    );

    const isPresentedToClient = watch(
        'mortgageProtectionInsurance.presentedToClient',
        presentedToClient
    );

    return (
        <>
            <Grid className={styles.grid}>
                <EditableCell
                    required
                    isEditing={!sectionCompleted}
                    name="mortgageProtectionInsurance.presentedToClient"
                    fieldType="select"
                    label="presentedToClient"
                    options={PRESENTED_TO_CLIENT_OPTIONS(i18n)}
                    value={presentedToClient}
                />
            </Grid>
            <Flex className={styles['bottom-container']}>
                {isPresentedToClient === 'NO' && (
                    <Comments
                        required
                        isEditing={!sectionCompleted}
                        name="mortgageProtectionInsurance.explanationIfNo"
                        label="explanation"
                        value={explanationIfNo}
                        error={explanationIfNoError !== undefined}
                    />
                )}
            </Flex>
        </>
    );
};
